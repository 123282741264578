import React from "react"
import { navigate } from "gatsby"

class Product extends React.Component {
  componentDidMount() {
    // 保留原有路径重定向
    navigate("/membership/vip")
  }

  render() {
    return <></>
  }
}

export default Product
